@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;600&display=swap');

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(243, 243, 243);
  font-family: 'Barlow', sans-serif;
}

::-webkit-scrollbar {
  width: 1.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #fa6161; 
  border-radius: 3px;
  background: #eee;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: orangered; 
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: red; 
}

.leaflet-container {
  width: inherit;
  height: inherit;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@media only screen and (max-width: 1024px) {
  html {
    font-size: 50%;
  }
}