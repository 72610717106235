.contact-us-main {
    width: 80%;
    height: 90vh;
    padding: 3% 5%;
    padding-top: 10rem;
}

.phontek-map {
    width: 100%;
    height: 40rem;
    padding-top: 5%;
    z-index: -2;
}

.contact-us-add-data {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.contact-us-address h2, p {
    font-size: 2rem;
    color: #5f5f5f;
}

.contact-us-address h2 {
    color: black;
}

.contact-us-address p a {
    text-decoration: none;
    color: #ed7203;
}

.contact-us-directions {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-us-directions button {
    border: 0.2rem solid #cb202d;
    border-radius: 1rem;
    outline: none;
    width: 15rem;
    height: 5rem;
    box-shadow: 0.2rem 0.2rem 0.5rem #ad5a61;
    margin:2rem;
    transition: 0.1s linear;
}

.contact-us-directions button a {
    color: #cb202d;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.contact-us-directions button:hover {
    width: 15.4rem;
    height: 5.2rem;
}

@media only screen and (max-width:468px) {
    .contact-us-add-data {
        flex-direction: column-reverse;
    }

    .contact-us-address h2, p {
       width: 70%;
    }
}