.product-view {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    align-items: flex-start;
    font-family: 'Barlow', sans-serif;
    padding-bottom: 3rem;
}

.product-view-card {
    width: 23%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3rem;
}

.product-view-card-img {
    position: relative;
    transition: 0.3s linear;
}

.product-view-card-img img {
    width: 100%;
    height: 100%;
    transition: 0.3s linear;
}

.product-view-read-more {
    background: brown;
    color: white;
    outline: none;
    border: none;
    width: 10rem;
    height: 3rem;
    position: absolute;
    bottom: 1rem;
    left: 33%;
    /* right: 30%; */
    display: none;
    cursor: text;
}

.product-view-card-img:hover {
    background: #ed7203;
    /* opacity: 1; */
    display:inline-block;
}

.product-view-card-img:hover img:hover {
    opacity: 0.2;
}

.product-view-card-img:hover .product-view-read-more {
    display: inline-block;
}

.product-view-card-header {
    font-size: 1.3rem;
}

@media only screen and (max-width: 768px) {
    .product-view-card {
        width: 30%;
    }
}

@media only screen and (max-width: 468px) {
    .product-view-card {
        width: 45%;
    }
}